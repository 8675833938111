import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ClientDB from './cloudia.library/clientDB'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
/*
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'
Sentry.init({
  dsn: 'https://c51dca330bfa4541a2265f3b9706dc14@sentry.golaboratory.jp/5',
  integrations: [new Integrations.Vue({ Vue, attachProps: true })],
})
*/
import VueIntro from 'vue-introjs'

import 'intro.js/introjs.css'

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueIntro)

const db = new ClientDB('')
db.upgrade()

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

import axios from 'axios'

/**
 * @file HTTP通信用クラス
 * @namespace rahmen
 */
/**
 * @module HttpClient
 */
export default class {
  /**
   * @constructor
   */
  constructor () {
    this.httpClient = axios.create({
      withCredentials: true
    })
  }

  /**
   * GETメソッド
   * @param {String} url - リクエスト先URL
   * @returns {Promise<Object>}
   */
  get (url, token) {

    return new Promise((resolve, reject) => {
      this.httpClient.interceptors.request.use((config) => {
        config.headers.Authorization = `Bearer ${token}`
        config.headers['Content-Type'] = 'application/json'
        console.info('httpClient.get', url, config)
        return config
      }, function (error) {
        return Promise.reject(error)
      })

      this.httpClient.get(url)
        .then((e) => {
          console.log(e)
          if (e.data === undefined) {
            reject(e)
            return
          }
          resolve(e.data)
        })
        .catch((e) => {
          console.error(e)
          reject(e)
        })
    })
  }

  /**
   * POSTメソッド
   * @param {String} url - リクエスト先URL
   * @param {Object} formData - POSTデータ
   * @returns {Promise<Object>}
   */
  post(url, formData, token) {

    return new Promise((resolve, reject) => {
      this.httpClient.interceptors.request.use((config) => {
        config.headers.Authorization = `Bearer ${token}`
        config.headers['Content-Type'] = 'application/json'
        console.info('httpClient.post', url, config)
        return config
      }, function (error) {
        return Promise.reject(error)
      })

      this.httpClient.post(url, {
        formData: JSON.stringify(formData)
      })
        .then((e) => {
          console.log(e)
          if (e.data === undefined) {
            reject(e)
            return
          }
          resolve(e.data)
        })
        .catch((e) => {
          console.error(e)
          reject(e)
        })
    })
  }

  /**
   * PUTメソッド
   * @param {String} url - リクエスト先URL
   * @param {Object} formData - PUTデータ
   * @returns {Promise<Object>}
   */
  put(url, formData, token) {

    return new Promise((resolve, reject) => {
      this.httpClient.interceptors.request.use((config) => {
        config.headers.Authorization = `Bearer ${token}`
        config.headers['Content-Type'] = 'application/json'
        console.info('httpClient.put', url, config)
        return config
      }, function (error) {
        return Promise.reject(error)
      })

      this.httpClient.put(url, {
        formData: JSON.stringify(formData)
      })
        .then((e) => {
          console.log(e)
          if (e.data === undefined) {
            reject(e)
            return
          }
          resolve(e.data)
        })
        .catch((e) => {
          console.error(e)
          reject(e)
        })
    })
  }

  /**
   * DELETEメソッド
   * @param {String} url - リクエスト先URL
   * @returns {Promise<Object>}
   */
  delete(url, token) {

    return new Promise((resolve, reject) => {
      this.httpClient.interceptors.request.use((config) => {
        config.headers.Authorization = `Bearer ${token}`
        config.headers['Content-Type'] = 'application/json'
        console.info('httpClient.delete', url, config)
        return config
      }, function (error) {
        return Promise.reject(error)
      })

      this.httpClient.delete(url)
        .then((e) => {
          console.log(e)
          if (e.data === undefined) {
            reject(e)
            return
          }
          resolve(e.data)
        })
        .catch((e) => {
          console.error(e)
          reject(e)
        })
    })
  }


  /**
   * ファイル(Blob)要求 (HTTP-GET)
   * @param {String} url - リクエスト先URL
   * @returns {Promise<Blob>}
   */
  requestFile(url, token) {
    return new Promise((resolve, reject) => {
      this.httpClient.interceptors.request.use((config) => {
        config.headers.Authorization = `Bearer ${token}`
        console.info('httpClient.requestFile', url, config)
        return config
      }, function (error) {
        return Promise.reject(error)
      })

      this.httpClient.get(url, {
        responseType: 'blob'
      })
        .then((e) => {
          console.log(e)
          if (e.data === undefined) {
            reject(e)
            return
          }
          resolve(e.data)
        })
        .catch((e) => {
          console.error(e)
          reject(e)
        })
    })
  }

  /**
   * ファイル(Blob)要求 (HTTP-POST)
   * @param {String} url - リクエスト先URL
   * @param {Object} formData - POSTデータ
   * @returns {Promise<Blob>}
   */
  requestFileByPost(url, formData, token) {
    return new Promise((resolve, reject) => {
      this.httpClient.interceptors.request.use((config) => {
        config.headers.Authorization = `Bearer ${token}`
        console.info('httpClient.requestFileByPost', url, config)
        return config
      }, function (error) {
        return Promise.reject(error)
      })

      this.httpClient.post(url, {
        formData: JSON.stringify(formData)
      },
        {
          responseType: 'blob'
        })
        .then((e) => {
          console.log(e)
          if (e.data === undefined) {
            reject(e)
            return
          }
          resolve(e.data)
        })
        .catch((e) => {
          console.error(e)
          reject(e)
        })
    })
  }


}

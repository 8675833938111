import viewMixin from '@/cloudia.library/mixins/viewMixins'

export default {
  name: 'ExpenditureSlip',
  mixins: [viewMixin],
  data: () => ({
    conditions: {},
    records: [],
    masters: [
      {
        controller: 'User',
        propName: 'userList'
      },
      {
        controller: 'Organize',
        propName: 'organizeList'
      },
      {
        controller: 'Subject',
        propName: 'subjectList'
      },
    ],
    cacheName: 'VoucherList'
  }),
  computed: {
    isSelected: function () {
      const rec = this.records.filter(r => {
        return r.selected
      })
      return rec.length !== 0
    },
    isRecordLoaded: function () {
      return this.records.length !== 0 ? true : false
    }
  },
  mounted() {
    this.Effects.loadStart()

    const initPromises = [
      this.loadMasters(this.masters),
      this.loadYearMonthList()
    ]

    this.conditions.congressmanNumber = this.userInfo.congressmanNumber

    Promise.all(initPromises).then(() => {
      this.findInputCache(this.cacheName).then(data => {
        console.log('Promise.all', data)
        if (data !== null && data !== undefined) {
          this.$set(this, 'conditions', data)
          this.onSearchClick()
        }
        this.removeInputCache(this.cacheName).then(() => {
          this.Effects.loadComplete()
        })
      })
    })
  },
  methods: {
    onSearchClick: function () {
      this.Effects.loadStart()
      this.clearInvalidMessage()
      this.HttpClient.post('/api/v1/ActivityCost/Search', this.conditions, this.authToken).then(data => {
        if (!data.isValid) {
          this.showInvalidMessage(data.summaryMessage, data.invalidList)
          this.Effects.loadComplete()
          return
        }
        
        data.payload.forEach(p => {
          p.selected = false
        })
        this.$set(this, 'records', data.payload)

        if (this.records.length === 0) {
          this.$bvToast.toast(
            '対象の伝票がありません',
            {
              title: 'メッセージ',
              autoHideDelay: 5000,
              variant: 'info'
            })
        } 

        this.Effects.loadComplete()
      })
    },
    onDownloadClick: function () {
      this.Effects.loadStart()

      const ids = this.records.filter(r => {
        return r.selected
      }).map(r => {
        return r.id
      })

      this.HttpClient.requestFileByPost(
        `/api/v1/Reports/DownloadExpenditureSlip`,
        ids,
        this.authToken).then(data => {
          const blob = new Blob([data], { type: 'application/pdf' })
          const elem = document.getElementById('pdfdownload')
          elem.href = window.URL.createObjectURL(blob)
          elem.click()
          this.Effects.loadComplete();
        })
    },
    onReturnToTopClick: function () {
      this.scrollToTop()
    },
    onAllSelectClick: function () {
      this.toggleSelected(true)
    },
    onAllReleaseClick: function () {
      this.toggleSelected(false)
    },
    toggleSelected: function (isSelected) {
      const self = this
      this.records.forEach((r, i) => {
        r.selected = isSelected
        self.records.splice(i, 1, r)
      })
    }
  }
}
import * as types from './mutation-types'

export const loadMasters = ({ commit }, { data, target }) => {
  console.log(types.LOAD_MASTERS, data, target)
  return new Promise(resolve => {
    if (data.payload !== null) {
      commit(types.LOAD_MASTERS, { id: target, data: data.payload })
    }
    resolve()
  })
}

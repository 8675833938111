import { mapGetters } from 'vuex'
import viewMixin from '@/cloudia.library/mixins/viewMixins'

export default {
  name: 'ExpenditureSlipList',
  mixins: [viewMixin],
  data: () => ({
    conditions: {},
    masters: [
      {
        controller: 'User',
        propName: 'userList'
      }
    ]
  }),
  computed: {
    ...mapGetters({
      fiscalYear: 'loginInfo/fiscalYear'
    }),
    filteredYearList: function () {
      return this.yearList.map(y => {
        return {
          value: y.id,
          text: y.isClosed ? `【締】${y.title}` : y.title
        }
      })
    }
  },
  mounted() {
    this.Effects.loadStart()

    const initPromises = [
      this.loadMasters(this.masters),
      this.loadYearList()
    ]

    this.conditions.congressmanNumber = this.userInfo.congressmanNumber
    this.conditions.selectedFiscalYear = this.fiscalYear

    Promise.all(initPromises).then(() => {
      this.Effects.loadComplete()
    })
  },
  methods: {
    onDownloadClick: function () {
      this.Effects.loadStart()

      this.HttpClient.requestFileByPost(
        `/api/v1/Reports/DownloadMonthlySummaryList`,
        this.conditions,
        this.authToken).then(data => {
          const blob = new Blob([data], { type: 'application/pdf' })
          const elem = document.getElementById('pdfdownload')
          elem.href = window.URL.createObjectURL(blob)
          elem.click()
          this.Effects.loadComplete();
        })
    }
  }
}
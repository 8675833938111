import * as types from './mutation-types'
import ClientDB from '@/cloudia.library/clientDB'
import conf from '@/cloudia.library/conf'

export const replaceMasters = ({ commit }) => {
  console.log('users.actions.loadLoginInfo')
  return new Promise((resolve, reject) => {
    const db = new ClientDB(conf.loginInfoStoreName)
    db.get(0).then((data) => {
      console.log(data)
      if (data === undefined || data.value === undefined) {
        reject(data)
        return
      }
      commit(types.SAVE_LOGIN_INFO, data.value)
      resolve()
    }).catch((e) => {
      reject(e)
    })
  })
}

export const loadUserInfo = ({ commit }) => {
  return new Promise((resolve, reject) => {
    const db = new ClientDB(conf.loginInfoStoreName)
    db.get(0).then((data) => {
      if (data === undefined || data.value === undefined) {
        reject(data)
        return
      }
      commit(types.SET_LOGIN_INFO, data.value)
      resolve()
    }).catch((e) => {
      reject(e)
    })
  })
}

export const registerFiscalYear = ({ commit }, fiscalYear) => {
  return new Promise((resolve) => {
    const db = new ClientDB(conf.loginInfoStoreName)
    db.get(0).then((data) => {
      console.log('registerFiscalYear', data)
      data.value.fiscalYear = fiscalYear
      db.register(0, data.value).then(() => {
        console.log('registerFiscalYear', data.value)
        commit(types.SET_LOGIN_INFO, data.value)
        resolve()
      })
    })
  })
}

export const setUserInfo = ({ commit }, { userInfo, authToken, secondFactorKey, enable2FactorAuth, fiscalYear, yearList }) => {
  commit(types.SET_LOGIN_INFO, { userInfo, authToken, secondFactorKey, enable2FactorAuth, fiscalYear, yearList  })

  return new Promise(resolve => {
    const db = new ClientDB(conf.loginInfoStoreName)
    db.register(0, { userInfo, authToken, secondFactorKey, enable2FactorAuth, fiscalYear, yearList  }).then(() => {
      resolve()
    })
  })
}
import viewMixin from '@/cloudia.library/mixins/viewMixins'

export default {
  name: 'Login',
  mixins: [viewMixin],
  data: () => ({
    rec: {
      loginId: '',
      password: '',
      twoFactorKeyword: ''
    },
    isFactor2: false,
  }),
  mounted() {
    this.$store.dispatch('loginInfo/setUserInfo', {userInfo: null, authToken: '', secondFactorKey: 0, enable2FactorAuth: false, fiscalYear: null, yearList: []}).then(() => {
    })
  },
  methods: {
    onLoginClick: function () {

      this.Effects.loadStart()

      this.HttpClient.post('/api/v1/User/TryLogin', this.rec, '').then((data) => {
        if (!data.isValid) {
          this.showInvalidMessage(data.summaryMessage, data.invalidList)
          this.Effects.loadComplete()
          return
        }

        if (data.payload.enable2FactorAuth)
        {
          this.isFactor2 = true
          this.twoFactorKeyword = ''
          this.Effects.loadComplete()
        }
        else
        {
          this.$store.dispatch('loginInfo/setUserInfo', data.payload).then(() => {
            this.$router.push({ name: 'Menu' })
          })
        }
      })
    },
    on2FactorAuthClick: function () {
      this.Effects.loadStart()
      this.HttpClient.post('/api/v1/User/TrySecondFactorAuth', this.rec, '').then((data) => {
        if (!data.isValid) {
          this.showInvalidMessage(data.summaryMessage, data.invalidList)
          this.Effects.loadComplete()
          return
        }
        this.isFactor2 = false
        this.$store.dispatch('loginInfo/setUserInfo', data.payload).then(() => {
          this.$router.push({ name: 'Menu' })
        })

      })
    }
  }
}
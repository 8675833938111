import { render, staticRenderFns } from "./UserSubjectMaintenance.vue?vue&type=template&id=2524196b"
import script from "./UserSubjectMaintenance.js?vue&type=script&lang=js&external"
export * from "./UserSubjectMaintenance.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
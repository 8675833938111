import * as getters from './getters'
import * as actions from './actions'
import mutations from './mutations'

export default {
  namespaced: true,
  state: {
    organizeList: [],
    subjectList: [],
    userList: []
  },
  mutations: mutations,
  actions: actions,
  getters: getters
}

import viewMixin from '@/cloudia.library/mixins/viewMixins'

export default {
  name: 'UserMaintenance',
  mixins: [viewMixin],
  data: () => ({
    records: [],
    suggestText: '',
    isEditing: false,
    target: {}
  }),
  mounted: function () {
    this.initialize()
  },
  computed: {
    filteredRecords: function () {
      if (this.suggestText === '') { return this.records }
      return this.records.filter(r => {
        return r.displayName.indexOf(this.suggestText) != -1
      })
    },
    isDeletable: function () {
      if (this.target.id === undefined || this.target.id === 0) { return false }
      return true
    }
  },
  methods: {
    initialize: function () {
      this.Effects.loadStart()
      this.HttpClient.get('/api/v1/User/GetAllWithInDeleted', this.authToken).then(data => {
        if (!data.isValid) {
          this.showInvalidMessage(data.summaryMessage, data.invalidList)
          this.Effects.loadComplete()
          return
        }

        this.records = data.payload

        if (this.records.length === 0) {
          this.$bvToast.toast(
            '対象のユーザがありません',
            {
              title: 'メッセージ',
              autoHideDelay: 5000,
              variant: 'info'
            })
        }

        this.Effects.loadComplete()
      })
    },
    fetch: function (id) {
      this.Effects.loadStart()

      this.HttpClient.get(`/api/v1/User/Find?id=${id}`, this.authToken).then(data => {
        if (!data.isValid) {
          this.showInvalidMessage(data.summaryMessage, data.invalidList)
          this.Effects.loadComplete()
          return
        }
        this.$set(this, 'target', data.payload)
        this.isEditing = true
        this.Effects.loadComplete()
      })
    },
    onNewClick: function () {
      this.clearInvalidMessage();
      this.fetch(0)
    },
    onEditButtonClick: function (id) {
      this.clearInvalidMessage();
      this.fetch(id)
    },
    onReturnButtonClick: function () {
      this.target = {}
      this.isEditing = false
    },
    onDeleteButtonClick: function () {
      this.clearInvalidMessage();
      this.Effects.loadStart()
      this.HttpClient.delete(`/api/v1/User/Delete?id=${this.target.id}`, this.authToken).then(data => {
        if (!data.isValid) {
          this.showInvalidMessage(data.summaryMessage, data.invalidList)
          this.Effects.loadComplete()
          return
        }

        this.showSuccessMessage(data.summaryMessage)

        this.initialize()
        this.isEditing = false
        this.target = {}
        this.Effects.loadComplete()
      })
    },
    onRegisterButtonClick: function () {
      this.clearInvalidMessage();
      this.Effects.loadStart()

      let p
      if (this.target.id === 0) {
        p = this.HttpClient.post(
          `/api/v1/User/Create`,
          this.target,
          this.authToken)
      } else {
        p = this.HttpClient.put(
          `/api/v1/User/Update?id=${this.target.id}`,
          this.target,
          this.authToken)
      }

      p.then(data => {

        if (!data.isValid) {
          this.showInvalidMessage(data.summaryMessage, data.invalidList)
          this.Effects.loadComplete()
          return
        }
        
        this.showSuccessMessage(data.summaryMessage)
        this.initialize()
        this.fetch(data.payload.id)
        this.Effects.loadComplete()
      })

    }
  }
}

import conf from './conf'

/**
 * @file IndexedDBラッピングクラス
 * @namespace rahmen
 */
/**
 * @module ClientDB
 */
export default class {
  /**
   * @constructor
   * @param {String} storeName - indexedDBのストア名
   */
  constructor (storeName) {
    this.storeName = storeName
  }

  /**
   * DB初期作成orスキーマ変更
   */
  upgrade () {
    const req = indexedDB.open(conf.defaultDBName, conf.storeVersion)
    req.onupgradeneeded = (event) => {
      console.log('rebuild databse')
      const db = event.target.result
      conf.storeNameList.forEach(n => {
        if (event.oldVersion > 1) {
          console.log('delete store', n)
          try {
            db.deleteObjectStore(n)
          } catch (e) {
            console.log('table remove error -- np ', e)
          }
        }
        db.createObjectStore(n, { keyPath: 'id' })
      })
    }
  }

  /**
   * データ取得
   * @param {Any} id 登録済みデータID
   * @returns {Promise<Object>}
   */
  get (id) {
    console.log('clientDB.get', id)
    return new Promise((resolve, reject) => {
      const req = indexedDB.open(conf.defaultDBName)
      req.onsuccess = (event) => {
        const db = event.target.result
        var q = db
          .transaction(this.storeName, 'readonly')
          .objectStore(this.storeName)
          .get(id)

        q.onsuccess = (e) => {
          console.log('clientDB.get', id, e.target.result)
          resolve(e.target.result || { value: undefined })
        }
        q.onerror = (e) => {
          reject(e)
        }
      }
    })
  }

  /**
   * 登録・更新処理
   * @param {Any} id 登録・更新用データID
   * @param {Any} data 登録・更新用データ
   */
  register (id, data) {
    console.log('clientDB.register', id, data)
    return new Promise((resolve, reject) => {
      const req = indexedDB.open(conf.defaultDBName)

      req.onsuccess = (event) => {
        console.log('clientDB.register', this.storeName, id, data, event.target.result)
        const db = event.target.result
        var q = db
          .transaction(this.storeName, 'readwrite')
          .objectStore(this.storeName)
          .put({ id: id, value: data })

        q.onsuccess = (e) => {
          resolve(e.target.result)
        }
        q.onerror = (e) => {
          reject(e)
        }
      }
    })
  }

  /**
   * 削除処理
   * @param {Any} id 削除用データID
   */
  delete (id) {
    return new Promise((resolve, reject) => {
      const req = indexedDB.open(conf.defaultDBName)
      req.onsuccess = (event) => {
        console.log('req.onsuccess', event)
        const db = event.target.result
        var q = db
          .transaction(this.storeName, 'readwrite')
          .objectStore(this.storeName)
          .delete(id)

        q.onsuccess = (e) => {
          resolve(e.target.result)
        }
        q.onerror = (e) => {
          reject(e)
        }
      }
    })
  }
}

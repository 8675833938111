/**
 * ローディングエフェクト表示
 */
function loadStart () {
  const e = document.getElementById('loading')
  if (e === null) { return }
  e.style.display = ''
}

/**
 * ローディングエフェクト非表示
 */
function loadComplete () {
  const e = document.getElementById('loading')
  if (e === null) { return }
  e.style.display = 'none'
}

export default {
  loadStart,
  loadComplete
}

import viewMixin from '@/cloudia.library/mixins/viewMixins'

export default {
  name: 'UserSubjectMaintenance',
  mixins: [viewMixin],
  data: () => ({
    records: [],
    suggestText: '',
    isEditing: false,
    target: {},
    masters: [
      {
        controller: 'Organize',
        propName: 'organizeList'
      },
    ]
  }),
  mounted: function () {
    this.initialize()
  },
  computed: {
    filteredRecords: function () {
      return this.records.filter(r => {
        const isfilltered = this.suggestText === '' ?
          true :
          r.description.indexOf(this.suggestText) !== -1 ||
            r.subject.indexOf(this.suggestText) !== -1
        return (r.userId === 0 || r.userId === this.userInfo.id) && isfilltered

      })
    },
    isDeletable: function () {
      if (this.target.id === undefined || this.target.id === 0) { return false }
      return true
    }
  },
  methods: {
    initialize: function () {
      this.Effects.loadStart()

      const initPromises = [
        this.loadMasters(this.masters),
      ]

      Promise.all(initPromises).then(() => {
        this.HttpClient.get('/api/v1/Subject/GetAll', this.authToken).then(data => {
          if (!data.isValid) {
            this.showInvalidMessage(data.summaryMessage, data.invalidList)
            this.Effects.loadComplete()
            return
          }

          this.$set(this, 'records', data.payload)

          if (this.records.length === 0) {
            this.$bvToast.toast(
              '対象のユーザがありません',
              {
                title: 'メッセージ',
                autoHideDelay: 5000,
                variant: 'info'
              })
          }

          this.Effects.loadComplete()
        })
      })
    },
    fetch: function (id) {
      this.Effects.loadStart()

      this.HttpClient.get(`/api/v1/Subject/FindByUser?id=${id}`, this.authToken).then(data => {
        if (!data.isValid) {
          this.showInvalidMessage(data.summaryMessage, data.invalidList)
          this.Effects.loadComplete()
          return
        }

        this.$set(this, 'target', data.payload)

        this.isEditing = true
        this.Effects.loadComplete()
      })
    },
    onNewClick: function () {
      this.fetch(0)
    },
    onEditButtonClick: function (id) {
      this.clearInvalidMessage();
      this.fetch(id)
    },
    onReturnButtonClick: function () {
      this.target = {}
      this.isEditing = false
    },
    onDeleteButtonClick: function () {
      this.clearInvalidMessage();
      this.Effects.loadStart()
      this.HttpClient.delete(`/api/v1/Subject/DeleteByUser?id=${this.target.id}`, this.authToken).then(data => {
        if (!data.isValid) {
          this.showInvalidMessage(data.summaryMessage, data.invalidList)
          this.Effects.loadComplete()
          return
        }

        this.showSuccessMessage(data.summaryMessage)

        this.initialize()
        this.isEditing = false
        this.target = {}
        this.Effects.loadComplete()
      })
    },
    onRegisterButtonClick: function () {
      console.log('onRegisterButtonClick', this.target)
      this.clearInvalidMessage();
      this.Effects.loadStart()
      console.log('onRegisterButtonClick', this.target)
      let p
      if (this.target.id === 0) {
        p = this.HttpClient.post(
          `/api/v1/Subject/CreateByUser`,
          this.target,
          this.authToken)
      } else {
        p = this.HttpClient.put(
          `/api/v1/Subject/UpdateByUser?id=${this.target.id}`,
          this.target,
          this.authToken)
      }

      p.then(data => {

        if (!data.isValid) {
          this.showInvalidMessage(data.summaryMessage, data.invalidList)
          this.Effects.loadComplete()
          return
        }
        
        this.showSuccessMessage(data.summaryMessage)
        this.initialize()
        this.fetch(data.payload.id)
        this.Effects.loadComplete()
      })

    }
  }
}
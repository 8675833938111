import viewMixin from '@/cloudia.library/mixins/viewMixins'

export default {
  name: 'Edit',
  mixins: [viewMixin],
  data: () => ({
    id: null,
    rec: {},
    isMobile: false,
    masters: [
      {
        controller: 'User',
        propName: 'userList'
      },
      {
        controller: 'Organize',
        propName: 'organizeList'
      },
      {
        controller: 'Subject',
        propName: 'subjectList'
      },
    ]
  }),
  mounted() {
  },
  computed: {
    filteredOrganizeList: function () {
      const ol = this.organizeList || []
      return ol.filter(l => {
        return !l.isDeleted || this.isAdmin
      })
    },
    filterdSubjectList: function () {
      if (this.rec.organizeCode === undefined) { return [] }
      return this.subjectList.filter(elem => {
        return (!elem.isDeleted || this.isAdmin) &&
          elem.organizeCode === this.rec.organizeCode &&
          (elem.userId === this.userInfo.id || elem.userId === 0)
      })
    },
    selectedInExpDiv: function () {
      if (this.rec.organizeCode === undefined) { return '0' }
      const ol = this.organizeList.find(o => {
        return o.organizeCode === this.rec.organizeCode
      })
      console.log('selectedInExpDiv', this.rec.organizeCode, this.organizeList)
      if (ol === null || ol === undefined) { return '0' }
      return ol.inExpDiv
    },
    proportedExpenditureAmount: function () {
      if (!window.isFinite(this.rec.expenditureAmount)) { return '' }
      if (!window.isFinite(this.rec.proportialRate)) { return '' }

      const amount = window.parseInt(this.rec.expenditureAmount, 10)
      const rate = window.parseInt(this.rec.proportialRate, 10)

      const result = Math.floor(amount * (rate / 100))

      return '' + result
    },
    autherName: function () {
      const u = this.userList.find(l => {
        return l.congressmanNumber === this.rec.congressmanNumber
      })
      if (u === null || u === undefined) { return '' }
      return u.displayName
    },
    filteredYearList: function () {
      return this.yearList.map(y => {
        return {
          value: y.id,
          text: y.isClosed ? `【締】${y.title}` : y.title
        }
      })
    }
  },
  watch: {
    $route: function () {
      console.log('watch.$route')
      this.initialize()
    }
  },
  created() {
  },
  mounted() {
    console.log('mounted')
    this.initialize()
    this.Effects.loadComplete()
  },
  methods: {
    initialize: function () {

      const initPromises = [
        this.loadMasters(this.masters),
        this.loadYearMonthList(),
      ]

      Promise.all(initPromises).then(() => {
        this.id = this.$route.params.id
        this.$set(this, 'id', this.$route.params.id)
        console.log('initialize', this.$route.params.id)

        this.fetchData().then(() => {
          if (this.id === 0) {
            this.$set(this.rec, 'congressmanNumber', this.userInfo.congressmanNumber)
          }
          this.Effects.loadComplete()
        })
      })

    },
    fetchData: function () {
      return new Promise(resolve => {
        this.HttpClient.get(`/api/v1/ActivityCost/Find?id=${this.id}`, this.authToken).then(data => {
          this.rec = data.payload
          this.scrollToTop()
          resolve()
        })
      })
    },
    onSubjectSelectChange: function () {
      console.log('onSubjectSelectChange', this.subjectList, this.rec.organizeCode, this.rec.subjectCode)
      const sub = this.subjectList.find(s => {
        return s.organizeCode == this.rec.organizeCode &&
          s.subjectCode == this.rec.subjectCode &&
          (s.userId === this.userInfo.id || s.userId === 0)
      })
      if (sub === null || sub === undefined) { return }
      if (this.rec.description === undefined || this.rec.description === '') {
        this.$set(this.rec, 'description', sub.subject)
      }
      this.$set(this.rec, 'proportialRate', '' + sub.proportialRate)
    },
    onDeleteButtonClick: function () {
      const res = window.confirm('この伝票の削除を行います。\nよろしいですか')
      if (!res) { return }
      this.Effects.loadStart()
      this.HttpClient.delete(`/api/v1/ActivityCost/Delete?id=${this.id}`, this.authToken).then(() => {
        this.$router.push({ name: 'VoucherList' }, () => { })
      })
    },
    onReturnButtonClick: function () {
      const res = window.confirm('未登録の情報を破棄して一覧に戻ります。\nよろしいですか')
      if (!res) { return }
      this.Effects.loadStart()
      this.$router.push({ name: 'VoucherList' }, () => { })
    },
    onRegisterButtonClick: function () {
      const res = window.confirm('この伝票を登録します。\nよろしいですか')
      if (!res) { return }

      let p
      if (this.id === 0) {
        this.rec.fiscalYear = this.fiscalYear
        p = this.HttpClient.post(
          `/api/v1/ActivityCost/Create`,
          this.rec,
          this.authToken
        )
      } else {
        p = this.HttpClient.put(
          `/api/v1/ActivityCost/Update?id=${this.id}`,
          this.rec,
          this.authToken
        )
      }
      console.log('onRegisterButtonClick', p, this.id)
      this.Effects.loadStart()

      p.then(data => {
        if (!data.isValid) {
          this.showInvalidMessage(data.summaryMessage, data.invalidList)
          this.Effects.loadComplete()
          return
        }

        this.showSuccessMessage(data.summaryMessage)

        if (this.id === 0) {
          console.log('onRegisterButtonClick', data.payload)
          // 新規の登録後は、連続新規
          this.initialize()
          return
        } else {
          this.rec = data.payload
          this.Effects.loadComplete()
        }
      })
    },
    onVisibleChanged: function (isVisible) {
      this.isMobile = isVisible
    }
  }
}
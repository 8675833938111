import Vue from 'vue'
import Vuex from 'vuex'
import masters from './masters'
import loginInfo from './loginInfo'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    masters: masters,
    loginInfo: loginInfo
  }
})

import viewMixin from '@/cloudia.library/mixins/viewMixins'

export default {
  name: 'List',
  mixins: [viewMixin],
  data: () => ({
    conditions: {},
    records: [],
    masters: [
      {
        controller: 'User',
        propName: 'userList'
      },
      {
        controller: 'Organize',
        propName: 'organizeList'
      },
      {
        controller: 'Subject',
        propName: 'subjectList'
      },
    ],
    cacheName: 'VoucherList'
  }),
  computed: {
    buttonName: function () {
      return this.isEditableFiscalYear
        ? '編集'
        : '参照'
    }
  },
  mounted() {
    this.Effects.loadStart()

    const initPromises = [
      this.loadMasters(this.masters),
      this.loadYearMonthList()
    ]

    this.conditions.congressmanNumber = this.userInfo.congressmanNumber

    Promise.all(initPromises).then(() => {
      this.findInputCache(this.cacheName).then(data => {
        console.log('Promise.all', data)
        if (data !== null && data !== undefined) {
          this.$set(this, 'conditions', data)
          this.onSearchClick()
        }
        this.removeInputCache(this.cacheName).then(() => {
          this.Effects.loadComplete()
        })
      })
    })
  },
  methods: {
    onSearchClick: function () {
      this.Effects.loadStart()
      this.clearInvalidMessage()
      this.HttpClient.post('/api/v1/ActivityCost/Search', this.conditions, this.authToken).then(data => {
        if (!data.isValid) {
          this.showInvalidMessage(data.summaryMessage, data.invalidList)
          this.Effects.loadComplete()
          return
        }
        
        this.$set(this, 'records', data.payload)

        if (this.records.length === 0) {
          this.$bvToast.toast(
            '対象の伝票がありません',
            {
              title: 'メッセージ',
              autoHideDelay: 5000,
              variant: 'info'
            })
        } 

        this.Effects.loadComplete()
      })
    },
    onEditButtonClick: function (id) {
      this.Effects.loadStart()

      this.saveInputCache(this.cacheName, this.conditions).then(() => {
        this.$router.push({ name: 'VoucherEdit', params: { id: id } })
      })
      
    },
    onReturnToTopClick: function () {
      this.scrollToTop()
    }
  }
}
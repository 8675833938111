import * as getters from './getters'
import * as actions from './actions'
import mutations from './mutations'

export default {
  namespaced: true,
  state: {
    userInfo: null,
    authToken: '',
    secondFactorKey: 0,
    enable2FactorAuth: false,
    fiscalYear: null,
    yearList: []
  },
  mutations: mutations,
  actions: actions,
  getters: getters
}

import viewMixin from '@/cloudia.library/mixins/viewMixins'

export default {
  name: 'Specified',
  mixins: [viewMixin],
  data: () => ({
    voucherNumber: '',
    year: null
  }),
  mounted() {
    this.initialize()
    this.Effects.loadComplete()
  },
  computed: {
    filteredYearList: function () {
      return this.yearList.map(y => {
        return {
          value: y.id,
          text: y.isClosed ? `【締】${y.title}` : y.title
        }
      })
    }
  },
  methods: {
    initialize: function () {
      const initPromises = [
        this.loadYearList(),
      ]

      Promise.all(initPromises).then(() => {
        this.Effects.loadComplete()
      })
    },
    onSearchClick: function () {
      this.Effects.loadStart()
      this.HttpClient.get(`/api/v1/ActivityCost/GetByCode?code=${this.voucherNumber}&y=${this.year}`, this.authToken).then(data => {
        if (!data.isExistsPayload) {
          this.showInvalidMessage(data.summaryMessage, data.invalidList)
          this.Effects.loadComplete()
          return;
        }
        this.$router.push({ name: 'VoucherEdit', params: { id: data.payload.id } }, () => { })
        return

      })
    }
  }
}
export const isLogedIn = (state) => {
  console.log('LoginInfo.getters.isLogedIn', state.userInfo)
  return state.userInfo !== null
}

export const userInfo = (state) => {
  console.log('LoginInfo.getters.userInfo', state.userInfo)
  return state.userInfo ?? {}
}

export const isAdmin = (state) => {
  console.log('LoginInfo.getters.isAdmin', state.userInfo)
  if (state.userInfo === undefined || state.userInfo.isAdministrator === undefined) { return false }
  return state.userInfo.isAdministrator
}

export const authToken = (state) => {
  return state.authToken
}

export const fiscalYear = (state) => {
  console.log('LoginInfo.getters.fiscalYear', state.fiscalYear)
  return state.fiscalYear
}

export const selectedFiscalYear = (state) => {
  console.log('LoginInfo.getters.selectedFiscalYear', state.yearList, state.fiscalYear)
  const y = state.yearList.find(x => {
    console.log('selectedFiscalYear', x.year, state.fiscalYear)
    return x.id === window.parseInt(state.fiscalYear)
  })
  if (y === null || y === undefined) { return '' }
  return y.title
}

export const isSelectedFiscalYear = (state) => {
  return state.fiscalYear !== null
}

export const isEditableFiscalYear = (state) => {
  if (state.userInfo.isAdministrator) { return true }
  if (state.fiscalYear === null) { return false }
  const y = state.yearList.find(x => {
    return x.id === window.parseInt(state.fiscalYear)
  })
  if (y === null || y === undefined) { return false }
  return !y.isClosed
}

export const isClosedFiscalYear = (state) => {
  if (state.fiscalYear === null) { return false }
  const y = state.yearList.find(x => {
    return x.id === window.parseInt(state.fiscalYear)
  })
  if (y === null || y === undefined) { return false }
  return y.isClosed
}

export const yearList = (state) => {
  return state.yearList
}
import { mapGetters } from 'vuex'
import viewMixin from '@/cloudia.library/mixins/viewMixins'

export default {
  mixins: [viewMixin],
  data: () => ({
    tempYear: null
  }),
  computed: {
    ...mapGetters({
      fiscalYear: 'loginInfo/fiscalYear',
      isSelectedFiscalYear: 'loginInfo/isSelectedFiscalYear'
    }),
    filteredYearList: function () {
      return this.yearList.map(y => {
        return {
          value: y.id,
          text: y.isClosed ? `【締】${y.title}` : y.title
        }
      })
    }
  },
  mounted() {
    const p = [
      this.clearInputCache(),
      this.loadYearList()
    ]
    this.$set(this, 'tempYear', this.fiscalYear)

    Promise.all(p).then(() => {
      this.Effects.loadComplete()
    })
  },
  methods: {
    onSubjectSelectChange: function () {
      this.$store.dispatch('loginInfo/registerFiscalYear', this.tempYear).then(() => {
        this.$bvModal.hide('bv-fiscal-year-modal')
      })
    }
  }
}
<template>
  <div id="app">
    <b-navbar
      toggleable="lg"
      type="dark"
      variant="primary"
      fixed="top"
    >
      <b-navbar-brand
        href="#"
      >
        政務活動費
        <span
          class="d-xs-block d-sm-block d-md-block d-lg-none d-xl-none ml-3"
          style="font-size: 1rem;"
        >
          {{ selectedFiscalYear }}
        </span>
      </b-navbar-brand>

      <b-nav-form>
        <b-button
          pill 
          size="sm"
          class="my-2 my-sm-0 ml-2"
          @click="onTutorialClick"
          v-if="isLogedIn"
        >
          <b-icon icon="question" />
        </b-button>
      </b-nav-form>

      <b-navbar-toggle target="nav-collapse" />

      <b-collapse
        v-if="isLogedIn"
        id="nav-collapse"
        is-nav
      >
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-form class="mr-3">
            {{ selectedFiscalYear }}
          </b-nav-form>

          <b-nav-item-dropdown
            right
            class="mr-3"
          >
            <template #button-content>
              <em>機能</em>
            </template>
            <b-dropdown-item to="/menu">
              メニュー
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: 'VoucherEdit', params: { id: 0 }}">
              伝票作成
            </b-dropdown-item>
            <b-dropdown-item to="/vouchar/list">
              伝票一覧
            </b-dropdown-item>
            <b-dropdown-item to="/reports/ExpenditureSlip">
              支出伝票
            </b-dropdown-item>
            <b-dropdown-item to="/reports/SlipCheckList">
              収支伝票チェックリスト
            </b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item-dropdown right>
            <template #button-content>
              <em>{{ userInfo.displayName }}</em>
            </template>
            <b-dropdown-item to="/">
              ログアウト
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <loading-effect />
    <!--
    <b-modal id="bv-fiscal-year-modal" hide-footer>
      <template #modal-title>
        年度選択
      </template>
      <div class="d-block text-center">
        <b-form-group
          label="年度"
          label-for="tempFiscalYear">

          <b-form-select
            id="tempFiscalYear"
            ref="tempFiscalYear"
            v-model="tempFiscalYear"
            size="sm"
            :options="yearList"/>

          <b-form-invalid-feedback id="tempFiscalYear"/>

        </b-form-group>
      </div>

      <b-row>
        <b-col>
          <b-button
            class="mt-3"
            block
            squared
            @click.prevent="$bvModal.hide('bv-fiscal-year-modal')">
            閉じる
          </b-button>
        </b-col>
        <b-col>
          <b-button
            class="mt-3"
            block
            squared
            variant="primary"
            @click.prevent="onSelectedYearRegistClick">
            適用
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    -->
    <router-view />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import LoadingEffect from './components/LoadingEffect.vue'

export default {
  name: 'App',
  components: { LoadingEffect },
  data: () => ({
    tempFiscalYear: 0
  }),
  computed: {
    ...mapGetters({
      userInfo: 'loginInfo/userInfo',
      isLogedIn: 'loginInfo/isLogedIn',
      selectedFiscalYear: 'loginInfo/selectedFiscalYear',
    })
  },
  methods: {
    onYearChangeClick: function () {
      this.$set(this, 'tempFiscalYear', this.fiscalYear)
      this.$bvModal.show('bv-fiscal-year-modal')
    },
    onSelectedYearRegistClick: function () {
      console.log('onSelectedYearRegistClick', this.tempFiscalYear)
      this.$store.dispatch('loginInfo/registerFiscalYear', this.tempFiscalYear).then(() => {
        this.$bvModal.hide('bv-fiscal-year-modal')
      })
    },
    onTutorialClick: function () {
      console.log('test', this.$intro)
      this.$intro().start()
    }
  }
}
</script>
<style>
html{
  scroll-behavior: smooth;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding-top: 60px;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>

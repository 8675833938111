import viewMixin from '@/cloudia.library/mixins/viewMixins'

export default {
  name: 'ExpenditureSlipList',
  mixins: [viewMixin],
  data: () => ({
    conditions: {},
    masters: [
      {
        controller: 'User',
        propName: 'userList'
      }
    ]
  }),
  computed: {
  },
  mounted() {
    this.Effects.loadStart()

    const initPromises = [
      this.loadMasters(this.masters),
      this.loadYearMonthList()
    ]

    this.conditions.congressmanNumber = this.userInfo.congressmanNumber
    this.conditions.fiscalYear = this.fiscalYear

    Promise.all(initPromises).then(() => {
      this.Effects.loadComplete()
    })
  },
  methods: {
    onDownloadClick: function () {
      this.Effects.loadStart()

      this.HttpClient.requestFileByPost(
        `/api/v1/Reports/DownloadExpenditureSlipList`,
        this.conditions,
        this.authToken).then(data => {
          const blob = new Blob([data], { type: 'application/pdf' })
          const elem = document.getElementById('pdfdownload')
          elem.href = window.URL.createObjectURL(blob)
          elem.click()
          this.Effects.loadComplete();
        })
    },
    onDownloadAllSlipClick: function () {
      this.Effects.loadStart()

      this.conditions.congressmanNumber = 0

      this.HttpClient.requestFileByPost(
        `/api/v1/Reports/DownloadExpenditureAllSlipList`,
        this.conditions,
        this.authToken).then(data => {
          const blob = new Blob([data], { type: 'application/pdf' })
          const elem = document.getElementById('pdfdownload')
          elem.href = window.URL.createObjectURL(blob)
          elem.click()
          this.Effects.loadComplete();
        })
    }
  }
}
import Vue from 'vue'
import VueRouter from 'vue-router'

import loginInfo from '@/store/loginInfo'
import store from '@/store'

import Login from '@/views/Login.vue'
import Menu from '@/views/Menu.vue'
import VoucherList from '@/views/Voucher/List.vue'
import VoucherEdit from '@/views/Voucher/Edit.vue'
import VoucherSpecified from '@/views/Voucher/Specified.vue'

import ReportsExpenditureSlip from '@/views/Reports/ExpenditureSlip.vue'
import ReportsSlipCheckList from '@/views/Reports/SlipCheckList.vue'
import ReportsTreasurer from '@/views/Reports/Treasurer.vue'
import ReportsExpenditureSlipList from '@/views/Reports/ExpenditureSlipList.vue'
import ReportsMonthlySummaryList from '@/views/Reports/MonthlySummaryList.vue'

import FiscalYearMaintenance from '@/views/MasterMaintenance/FiscalYearMaintenance.vue'
import UserMaintenance from '@/views/MasterMaintenance/UserMaintenance.vue'
import OrganizeMaintenance from '@/views/MasterMaintenance/OrganizeMaintenance.vue'
import SubjectMaintenance from '@/views/MasterMaintenance/SubjectMaintenance.vue'
import UserSubjectMaintenance from '@/views/MasterMaintenance/UserSubjectMaintenance.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: { allowAnonymous: true }
  },
  {
    path: '/menu',
    name: 'Menu',
    component: Menu
  },
  {
    path: '/vouchar/list',
    name: 'VoucherList',
    component: VoucherList
  },
  {
    path: '/vouchar/edit/:id',
    name: 'VoucherEdit',
    component: VoucherEdit
  },
  {
    path: '/vouchar/specified',
    name: 'VoucherSpecified',
    component: VoucherSpecified
  },
  {
    path: '/reports/ExpenditureSlip',
    name: 'ReportsExpenditureSlip',
    component: ReportsExpenditureSlip
  },
  {
    path: '/reports/SlipCheckList',
    name: 'SlipCheckList',
    component: ReportsSlipCheckList
  },
  {
    path: '/reports/treasurer',
    name: 'treasurer',
    component: ReportsTreasurer
  },
  {
    path: '/reports/ExpenditureSlipList',
    name: 'ExpenditureSlipList',
    component: ReportsExpenditureSlipList
  },
  {
    path: '/reports/MonthlySummaryList',
    name: 'MonthlySummaryList',
    component: ReportsMonthlySummaryList
  },
  {
    path: '/master/fiscalyear',
    name: 'FiscalYearMaintenance',
    component: FiscalYearMaintenance
  },
  {
    path: '/master/user',
    name: 'UserMaintenance',
    component: UserMaintenance
  },
  {
    path: '/master/organize',
    name: 'OrganizeMaintenance',
    component: OrganizeMaintenance
  },
  {
    path: '/master/subject',
    name: 'SubjectMaintenance',
    component: SubjectMaintenance
  },
  {
    path: '/master/usersubject',
    name: 'UserSubjectMaintenance',
    component: UserSubjectMaintenance
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.allowAnonymous) {
    next()
    return
  }

  store.dispatch('loginInfo/loadUserInfo').then(() => {
    if (loginInfo.getters.isLogedIn) {
      next()
    } else {
      next({ name: 'Login' })
    }
  })
})

export default router
